<template>
  <tr>
    <td>
      <input type="date" ref="startDate" class="form-control" v-model="request.startDate" required :min="minimumStartDate" :max="maximumStartDate" />
    </td>
    <td>{{ request.startDate | toLongDateString }}</td>
    <td>
      <!--<input type="number" class="form-control duration" style="max-width:70px" min="1" v-bind:disabled="employee.isFieldEmployee" v-model.number="request.duration" required />-->
      <input type="number" class="form-control duration" style="max-width:70px" min="1" :max="maximumDuration" v-model.number="request.duration" required />
    </td>
    <td>{{ request.holidays }}</td>
    <td>{{ request.resumptionDate }}</td>
    <td>{{ request.resumptionDate | toLongDateString }}</td>
    <td v-if="allowRemoveRequest" class="text-right font-weight-bolder"><span class="action-btn mr-2 font-weight-bold h5" @click="removeRequest">&#9473;</span></td>
  </tr>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer;
    font-size: 30px;
  }

  fieldset {
    border: 0;
  }
</style>

<script>

  import Vue from 'vue'
  import { mapState } from 'vuex'
  import { toLongDateString, isLeaveStartDateLessThanCurrentDate, fromServerDate, getLeaveDates, flattenHolidayDates } from '@/utils/date'
  import isSameDay from 'date-fns/isSameDay'

  export default {
    name: 'recall-vacation-entry',
    components: {
    },
    props: {
      id: {
        type: String,
        required: false,
        default: 'roleList'
      },
      requests: {
        type: Array,
        required: true
      },
      request: {
        type: Object,
        required: true
      },
      minimumStartDate: {
        type: String,
        required: true,
      },
      maximumStartDate: {
        type: String,
        required: true,
      },
      maximumDuration: {
        type: Number,
        required: true,
      },
      previouslyScheduledVacationRequests: {
        type: Array,
        required: true
      },
      employee: {
        type: Object,
        required: true,
      }
    },
    filters: {
      toLongDateString
    },
    data: () => ({
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        holidays: state => state.publicHolidayModule.publicHolidays,
      }),
      holidayDates() {
        let holidays = []
        if (this.holidays && this.holidays.length) {
          this.holidays.forEach((holiday) => {
            const dates = flattenHolidayDates(fromServerDate(holiday.startDate), holiday.numberOfDays)
            dates.forEach((date) => {
              holidays.push(date)
            })
          })
        //  return this.holidays.map(holiday => new Date(Date.parse(holiday.startDate)))
        }
        return holidays
      },
      allowRemoveRequest() {
        return this.requests.length > 1 && typeof this.request.id !== 'number'
      },
    },
    watch: {
      'request.duration'() {
        this.setEndDate()
      }
    },
    methods: {
      setEndDate() {
        if (this.request.startDate && this.request.duration) {
          //var dates = getLeaveDates(this.request.startDate, this.request.duration, this.employee.isFieldEmployee, this.employee.isFieldEmployee, [this.holidayDates])
          var dates = getLeaveDates(this.request.startDate, this.request.duration, false, false, this.holidayDates)
          this.request.endDate = dates.endDate
          this.request.resumptionDate = dates.resumptionDate
          this.request.holidays = dates.numberOfHolidays
          if (this.checkDatesClash()) {
            this.$el.querySelector('.duration').classList.add('collission')
            //this.$el.querySelector('.duration').setCustomValidity('there is a date collission with a previously scheduled request. kindly correct all invalid entries')
          }
          else {
            this.$el.querySelector('.duration').classList.remove('collission')
            //alert('no clash')
            //this.$el.querySelector('.duration').setCustomValidity('')
          }
        }
      },
      checkDatesClash() {
        let otherRequests = this.requests.filter(vr => this.request != vr)
        otherRequests = otherRequests || [];
        var collisionExists = false
        const previouslyScheduledVacationRequests = this.previouslyScheduledVacationRequests || [];
        [...otherRequests, ...previouslyScheduledVacationRequests].forEach((vr) => {
          const requestStartDate = new Date(this.request.startDate)
          const requestEndDate = new Date(this.request.endDate)
          const otherRequestStartDate = new Date(vr.startDate)
          const otherRequestEndDate = new Date(vr.endDate)
          requestStartDate.setHours(0, 0, 0, 0)
          requestEndDate.setHours(0, 0, 0, 0)
          otherRequestStartDate.setHours(0, 0, 0, 0)
          otherRequestEndDate.setHours(0, 0, 0, 0)

          if ((otherRequestStartDate <= requestEndDate) && (otherRequestEndDate >= requestStartDate)) {
            collisionExists = true
            return
          }
        })
        return collisionExists
      },
      removeRequest() {
        for (var i = 0; i < this.requests.length; i++) {
          if (this.requests[i] === this.request) {
            this.requests.splice(i, 1);
          }
        }
      }
    },
    filters: {
      toLongDateString
    },
    mounted() {
      const self = this
      const startDateElement = this.$refs.startDate
      startDateElement.addEventListener('change', (event) => {
        let selectedDate = new Date(event.target.value)
        var day = new Date(selectedDate).getUTCDay();
        let startsOnHoliday = self.holidayDates.filter((holiday) => isSameDay(holiday, selectedDate)).length
        if ([6, 0].includes(day) || startsOnHoliday) {
          event.preventDefault();
          alert('Weekends / public holidays not allowed');
          event.target.value = '';
          self.request.startDate = null
          self.request.holidays = null
          self.request.endDate = null
          self.request.resumptionDate = null
          startDateElement.click()
        }
        Vue.nextTick(() => {
          if (self.request.startDate) {
            if (isLeaveStartDateLessThanCurrentDate(self.request.startDate)) {
              self.showLeaveStartDateWarning = true
            }
            else {
              self.setEndDate()
            }
          }
        })
      });
    }
  }
</script>
