<template>
  <div id="vacationRecallView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>vacation recall request</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal v-if="selectedVacationRequest" :static="true" :show="showRecallModal" title="vacation recall request" size="large" :closeModalEvent="closeViewModalEvent">
          <transition name="slide-fade">
            <app-alert v-if="errorMessage" :type="errorAlertType" :message="errorMessage" @alertclose="errorMessage = ''" />
          </transition>
          <form id="recallForm">
            <fieldset v-bind:disabled="isSavingRequest">
              <div class="form-group row mt-4">
                <span class="col-md-3">start date</span>
                <span>{{ selectedVacationRequest.startDate | toLongDateString }}</span>
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">end date</span>
                <span>{{ selectedVacationRequest.endDate | toLongDateString }}</span>
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">resumption date</span>
                <span>{{ selectedVacationRequest.resumptionDate | toLongDateString }}</span>
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">vacation days</span>
                <span>{{ selectedVacationRequest.duration }}</span>
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">total recall days</span>
                <input type="number" id="recallDays" class="form-control col-md-1" v-model="request.numberOfDays" required min="1" :max="selectedVacationRequest.duration" />
              </div>
              <div class="form-group row mt-4" v-if="request.numberOfDays && selectedVacationRequest.duration > request.numberOfDays">
                <span class="col-md-3 mr-o">actual days taken</span>
                <table class="col-md-8 mr-0" style="display: inline-table">
                  <thead>
                    <tr>
                      <th style="width:10% !important;">start date</th>
                      <th style="width:23% !important;"></th>
                      <th style="width:17%;">days</th>
                      <th style="width:10%;">holidays</th>
                      <th style="width:15%;">resumption</th>
                      <th style="width:23% !important;"></th>
                      <th style="width:2%; text-align:center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr is="RecallVacationRequestEntry" v-for="(theRequest, index) in request.associatedVacationRequests" :request="theRequest" :requests="request.associatedVacationRequests" :previouslyScheduledVacationRequests="previouslyScheduledVacationRequests" :employee="employeeInfo" :minimumStartDate="minimumStartDate" :maximumStartDate="maximumStartDate" :maximumDuration="selectedVacationRequest.duration" v-bind:key="request.id"></tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="7" class="text-right font-weight-bolder" v-show="allowAddNewRequest"><span class="action-btn mr-2 font-weight-bold h5" @click="tryAddNewRequest">&#9545;</span></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">reason</span>
                <textarea id="reasonForChange" class="form-control col-md-8" v-model="request.reasonForChange" required rows="3" />
              </div>
              <div class="form-group row mt-4">
                <label class="col-md-3"></label>
                <div class="col-md-8">
                  <employee-autocomplete label="approver" errorLabel="Please, select a valid approver" :items="searchedApprovers" id="supv_search" :sData="request.intendedApproverName" :employeeSearchClearedEvent="approverSearchClearedEvent" :employeeSearchStartedEvent="approverSearchStartedEvent" :employeeSelectedEvent="approverSelectedEvent" v-bind:isShown="true" isRequired :isDisabled="disableFields || isSearchingEmployees" />
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancel" v-bind:disabled="isSavingRequest">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click.prevent="saveChangeRequest" v-bind:disabled="isSavingRequest">Submit<span class="spinner"></span></button>
          </template>
        </app-modal>
        <fieldset v-if="!isCompleted" v-bind:disabled="isSavingRequest">
          <employee-autocomplete v-show="currentUserCanRaiseRequestForOtherRegularEmployees || currentUserCanRaiseRequestForExpatriates" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired />
        </fieldset>
        <data-table v-if="vacationRequests.length" id='vacationRequests-table' :headers='tableHeaders' :items='vacationRequests'>
          <template v-slot:item.employeeName="{ item }">
            <a href="#" @click="selectRequest(item)">
              {{ item.employeeInfo.fullName }}
            </a>
          </template>
          <template v-slot:item.startDate="{ item }">
            {{ item.startDate | toShortDateString }}
          </template>
          <template v-slot:item.endDate="{ item }">
            {{ item.endDate | toShortDateString }}
          </template>
          <template v-slot:item.duration="{ item }">
            {{ item.duration }}
          </template>
          <template v-slot:item.holidays="{ item }">
            {{ item.holidays }}
          </template>
          <template v-slot:item.resumptionDate="{ item }">
            {{ item.resumptionDate | toShortDateString }}
          </template>
        </data-table>
      </div>
    </div>
    <!--<div class="row">
      <div class="col">

      </div>
    </div>-->
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'
  import { FETCH_PUBLICHOLIDAYS } from '@/store/action-type'

  import { employeeService, vacationRequestService, leaveRequestService, vacationMasterService  } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { CLOSE_MODAL_EVENT } from '@/utils/constants'

  import Enums from '@/utils/enums'
  import { fromServerDate } from '@/utils/date'
  import { toShortDateString, toLongDateString } from '@/filters'

  import { v4 as uuidv4 } from 'uuid';

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'
  import DataTable from '@/components/ui/DataTable'
  import RecallVacationRequestEntry from '@/components/vacation/SingleEntryForRecall'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'

  export default {
    name: 'vacation-recall-view',
    components: {
      AppAlert,
      AppModal,
      EmployeeAutocomplete,
      DataTable,
      RecallVacationRequestEntry
    },
    mixins: [GeneralMixin, AlertMixin, ErrorMixin, AccessControlMixin, LeaveRequestMixin],
    data: () => ({
      request: {
        vacationRequestId: null,
        numberOfDays: null,
        associatedVacationRequests: [],
        intendedApproverId: '',
        intendedApproverName: '',
        requestType: Enums.RequestType.VacationDaysRecall.value,
        requestActions: []
      },
      vacationRequests: [],
      previouslyScheduledVacationRequests: [],
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
      },
      showRecallModal: false,
      selectedVacationRequest: null,
      isSavingRequest: false,
      isChangingUserStatus: false,
      searchedEmployees: [],
      tableHeaders: [
        {
          name: 'employeeName',
          title: 'employee name'
        },
        {
          name: 'startDate',
          title: 'start date'
        },
        {
          name: 'endDate',
          title: 'end date'
        },
        {
          name: 'duration',
          title: 'duration'
        },
        {
          name: 'holidays',
          title: 'holidays'
        },
        {
          name: 'resumptionDate',
          title: 'resumption date'
        }
      ],
      closeViewModalEvent: CLOSE_MODAL_EVENT,
      errorMessage: ''
    }),
    computed: {
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => employee.isRegularEmployee || employee.isExpatriateEmployee)
        }
        return []
      },
      leaveRequestCanBeRaisedForEmployee() {
        return true
      },
      sumOfNewRequests() {
        return this.request.associatedVacationRequests.reduce(function (a, b) {
          console.log(b)
          return a + b.duration;
        }, 0)
      },
      allowAddNewRequest() {
        return this.sumOfNewRequests < this.selectedVacationRequest.duration - this.request.numberOfDays
      },
      minimumStartDate() {
        if (this.selectedVacationRequest) {
          return fromServerDate(this.selectedVacationRequest.startDate)
        }
        return ''
      },
      maximumStartDate() {
        if (this.selectedVacationRequest) {
          return fromServerDate(this.selectedVacationRequest.endDate)
        }
        return ''
      }
    },
    watch: {
      showRecallModal(value) {
        if (value) {
          this.$nextTick(() => {
            document.getElementById('recallDays').focus()
          })
        }
      },
      'request.intendedApproverId': {
        immediate: true,
        handler(newValue, oldValue) {
          if (this.request.associatedVacationRequests.length) {
            const self = this
            this.request.associatedVacationRequests.forEach(function (request) {
              request.intendedApproverId = newValue
            })
          }
        }
      },
      'request.intendedApproverName': {
        immediate: true,
        handler(newValue, oldValue) {
          if (this.request.associatedVacationRequests.length) {
            const self = this
            this.request.associatedVacationRequests.forEach(function (request) {
              request.intendedApproverName = newValue
            })
          }
        }
      }
    },
    methods: {
      resetRequestData() {
        this.request.employeeId = null
        this.request.vacationRequestId = null
        this.request.numberOfDays = null
        this.request.intendedApproverId = ''
        this.request.intendedApproverName = ''
        this.request.reasonForChange = ''
        this.previouslyScheduledVacationRequests.splice(0, this.previouslyScheduledVacationRequests.length)
        this.request.associatedVacationRequests.splice(0, this.request.associatedVacationRequests.length)
      },
      resetData() {
        this.employeeInfo.employeeId = ''
        this.employeeInfo.employeeNo = ''
        this.employeeInfo.fullName = ''
        this.employeeInfo.supervisorId = ''
        this.employeeInfo.supervisorName = ''
        this.resetRequestData()
        this.vacationRequests.splice(0, this.vacationRequests.length)
        this.showRecallModal = false
        this.selectedVacationRequest = null
        this.isSavingRequest = false
        this.isChangingUserStatus = false
      },
      fetchEmployeeData(employee) {
        eventBus.$emit(Events.LongOperationStarted, '')
        const self = this
        let tasks = []
        const year = new Date().getFullYear()
        tasks.push(employeeService.getEmployee(employee.employeeId))
        tasks.push(vacationRequestService.getVacationRequestsForRecall(employee.employeeId, year))
        Promise.all(tasks).then((results) => {
          self.resetData()
          const employee = results[0].data
          const vacationRequests = results[1]
          self.employeeInfo.employeeId = employee.employeeId
          self.employeeInfo.employeeNo = employee.employeeNo
          self.employeeInfo.fullName = employee.fullName
          self.employeeInfo.supervisorId = employee.supervisorId
          self.employeeInfo.supervisorName = employee.supervisorName
          if (vacationRequests && vacationRequests.length) {
            for (const vacationRequest of vacationRequests) {
              self.vacationRequests.push(vacationRequest)
            }
          }
          else {
            self.showErrorMessage('no vacation schedule found for vacation recall')
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      selectRequest(request) {
        this.resetRequestData()
        eventBus.$emit(Events.LongOperationStarted, 'Fetching data')
        const tasks = []
        tasks.push(store.dispatch(FETCH_PUBLICHOLIDAYS, request.vacationMaster.vacationYear))
        tasks.push(vacationMasterService.getVacationMasterFor(this.employeeInfo.employeeId, request.vacationMaster.vacationYear))
        Promise.all(tasks).then((vacationMaster) => {
          this.selectedVacationRequest = { ...request }
          this.request.employeeId = this.employeeInfo.employeeId
          this.request.vacationRequestId = request.id
          this.request.intendedApproverId = this.employeeInfo.supervisorId
          this.request.intendedApproverName = this.employeeInfo.supervisorName
          if (vacationMaster.vacationRequests && vacationMaster.vacationRequests.length) {
            let existingRequests = vacationMaster.vacationRequests.filter((er) => !er.isCancelled && er.id != request.id)
            //this.vacationMaster.existingVacationRequests = [...existingRequests]
            for (const previousSchedule of existingRequests) {
              this.previouslyScheduledVacationRequests.push(previousSchedule)
            }
          }
          this.tryAddNewRequest()
          this.errorMessage = ''
          this.showRecallModal = true
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      saveChangeRequest() {
        const self = this
        self.closeMessage()
        if (self.validateRequest()) {
          self.closeMessage()
          self.isSavingRequest = true
          let data = { ...self.request }
          if (!self.request.id) {
            data.employeeId = self.employeeInfo.employeeId
          }
          else {
            data.comment = self.comment
          }
          data.associatedVacationRequests.forEach(d => delete d.id)
          if (this.selectedVacationRequest.duration == data.numberOfDays) {
            data.associatedVacationRequests.splice(0, data.associatedVacationRequests.length)
          }
          leaveRequestService.schedule(data, self.employeeInfo.employeeId)
            .then(() => {
              self.requestState = Enums.RequestState.Submitted
              self.showSuccessMessage('request submitted successfully')
              self.isCompleted = true
              self.resetData()
            })
            .catch((error) => {
              console.log(error.appResultCode)
              if (error instanceof ELeaveError) {
                console.log(error.appResultCode)
                self.showErrorMessage(ErrorMessages.getErrorMessageByErrorCode(error.appResultCode))
              }
              else {
                self.showErrorMessage(error)
              }
            }).finally(() => {
              self.isSavingRequest = false
            })
        }
      },
      cancel() {
        this.showRecallModal = false
      },
      async validateRequest() {
        let errorMessage = ''
        const approverField = document.getElementById('supv_search')
        if (approverField) {
          let invalidApprover = false
          if (!this.request.intendedApproverId) {
            errorMessage += "No approver selected \n"
            approverField.setCustomValidity("No approver selected")
            invalidApprover = true
          }
          if (this.request.intendedApproverId.toLowerCase() == this.employeeInfo.employeeId.toLowerCase()) {
            errorMessage += "You cannot approve your own request \n"
            approverField.setCustomValidity("You cannot approve your own request")
            invalidApprover = true
          }
          if (!invalidApprover) {
            approverField.setCustomValidity("")
          }
        }
        const durationFIelds = document.getElementsByClassName('duration')
        durationFIelds.forEach(field => field.setCustomValidity(''))
        if (this.selectedVacationRequest.duration - this.request.numberOfDays - this.sumOfNewRequests != 0) {
          errorMessage += `total duration must equal ${this.selectedVacationRequest.duration - this.request.numberOfDays} \n`
          durationFIelds.forEach(field => field.setCustomValidity(`total duration must equal ${this.selectedVacationRequest.duration - this.request.numberOfDays}`))
        }
        const datesWithCollision = [...durationFIelds].filter(field => field.classList.contains('collission'))
        if (datesWithCollision.length) {
          errorMessage += "there is a date collission with a previously scheduled request. kindly correct all invalid entries \n"
        }
        datesWithCollision.forEach(field => field.setCustomValidity('there is a date collission with a previously scheduled request. kindly correct all invalid entries'))
        const requestForm = document.getElementById('recallForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            ".form-control:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
          this.errorMessage = errorMessage
          return false
        }
        else {
          const approverDirectReports = await employeeService.getEmployeeDirectReports(this.request.intendedApproverId)
          if (approverDirectReports && approverDirectReports.length) {
            requestForm.classList.remove('was-validated');
            return true
          }
          else {
            this.showErrorMessage('invalid approver. approver is not a supervisor')
            return false
          }
        }
      },
      tryAddNewRequest() {
        //const durationFIelds = document.getElementsByClassName('duration')
        //durationFIelds.forEach(field => field.setCustomValidity(''))
        //const vacationRequestForm = document.getElementById('recallForm')
        //let isValidForm = false
        //if (vacationRequestForm) {
        //  isValidForm = vacationRequestForm.checkValidity()
        //  if (isValidForm) {
        //    vacationRequestForm.classList.remove('was-validated');
        //  }
        //}
        //if (!this.request.associatedVacationRequests.length || isValidForm) {
        //  this.request.associatedVacationRequests.push({
        //    id: uuidv4(),
        //    startDate: '',
        //    endDate: '',
        //    resumptionDate: '',
        //    duration: null, //this.employeeInfo.isFieldEmployee ? (this.vacationMaster.unScheduledVacationDays >= 28 ? 28 : this.vacationMaster.unScheduledVacationDays) : null,
        //    holidays: null,
        //    intendedApproverId: this.request.intendedApproverId,
        //    intendedApproverName: this.request.intendedApproverName,
        //  })
        //}
        this.request.associatedVacationRequests.push({
          id: uuidv4(),
          startDate: '',
          endDate: '',
          resumptionDate: '',
          duration: null, //this.employeeInfo.isFieldEmployee ? (this.vacationMaster.unScheduledVacationDays >= 28 ? 28 : this.vacationMaster.unScheduledVacationDays) : null,
          holidays: null,
          intendedApproverId: this.request.intendedApproverId,
          intendedApproverName: this.request.intendedApproverName,
        })
      }
    },
    filters: {
      toShortDateString,
      toLongDateString
    },
    mounted() {
      eventBus.$on(CLOSE_MODAL_EVENT, this.cancel)
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.fetchEmployeeData(store.state.userModule.currentUser)
      })
    },
    beforeDestroy() {
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>
